<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header id="modalTitle" class="modal-header">
          <div class="modal-force-number">{{ force }}</div>
          <div>Ordernummer: {{ orderNumber }}</div>
          {{ currentFileName }}
          <button type="button" class="modal-close" @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="modal-close-svg">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
            </svg>
          </button>
        </header>
        <div class="modal-content-wrapper">
          <div class="modal-column-left">
            <div class="action-bar-left"></div>
            <div class="file-area">
              <div v-for="file in myOrderFiles" :key="file" class="single-file">
                <a href="#" @click.prevent="getPDF(file)">
                  <img class="file-image" src="../assets/Adobe-PDF-File-Icon-01.png" />
                </a>
                <div class="file-text">{{ file.substring(file.lastIndexOf('/') + 1) }}</div>
              </div>
            </div>
          </div>
          <div class="modal-column-right">
            <div class="action-bar-right">
              <img src="../assets/arrow-alt-circle-left-solid.svg" class="svg-icon svg-icon-arrow" @click.prevent="prevPage" />

              <span class="page-info">
                <span>{{ pageNum }} av {{ pageCount }}</span>
              </span>
              <img src="../assets/arrow-alt-circle-right-solid.svg" class="svg-icon svg-icon-arrow" @click.prevent="nextPage" />
            </div>

            <div v-if="errorMessage !== ''" class="pg-center">
              <p class="errorMessageText">{{ errorMessage }}</p>
            </div>
            <div v-if="loading && errorMessage === ''" class="pg-center">
              <Spinner></Spinner>
            </div>
            <div class="canvas-area">
              <canvas class="modal-canvas" id="pdf-render"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions } from 'vuex';
//import PGPDFViewer from '@/components/PG-PDFViewer';
import axiosAPI from '@/services/axiosAPI';
import pdfjsLib from 'pdfjs-dist/build/pdf';
import Spinner from '@/components/utils/Spinner';
import 'pdfjs-dist/web/pdf_viewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js';
//pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.js`;
export default {
  name: 'PGPDFModal',
  components: {
    Spinner
  },
  props: {
    order: {
      type: Object
    },
    force: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      showError: false,
      amount: 0,
      orderId: this.order.order_id,
      orderNumber: '',
      myOrderFiles: [],
      myForceCount: 0,
      currentFileName: 'Ingen PDF',
      pageCount: 0,
      pdfDoc: null,
      pageNum: 1,
      pageIsRedering: false,
      pageNumIsPending: null,
      scale: 1.5,
      canvas: null,
      context: null
    };
  },
  async mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    });
  },
  async updated() {
    this.myForceCount = this.force;
    this.myOrderFiles = this.order.order_files;
    this.orderNumber = this.order.order_number;
  },
  methods: {
    ...mapActions({
      fetchOrderFiles: 'orders-utils/fetchOrderFiles'
    }),
    close() {
      this.errorMessage = '';
      if (this.context) {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.beginPath();
        this.canvas = null;
        this.context = null;
      }

      this.$emit('close');
    },
    prevPage() {
      if (this.errorMessage) {
        return;
      }
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.queueRenderPage(this.pageNum);
    },
    nextPage() {
      if (this.errorMessage) {
        return;
      }
      if (this.pageNum >= this.pdfDoc.numPages) {
        return;
      }
      this.pageNum++;
      this.queueRenderPage(this.pageNum);
    },
    async getPDF(file) {
      this.loading = true;
      this.currentFileName = file.substring(file.lastIndexOf('/') + 1);

      try {
        this.pdfDoc = null;
        this.pageNum = 1;
        this.pageIsRedering = false;
        this.pageNumIsPending = null;
        this.scale = 1.5;

        this.canvas = document.querySelector('#pdf-render');
        this.context = this.canvas.getContext('2d');

        const url = await this.downloadPDF(file);

        // Get Document
        pdfjsLib
          .getDocument(url)
          .promise.then((pdfDoc_) => {
            this.pdfDoc = pdfDoc_;
            this.pageCount = this.pdfDoc.numPages;
            this.renderPage(this.pageNum);
          })
          .catch((error) => {
            //    console.log(error);
            this.errorMessage = 'PDF filen kunde inte laddas från servern.';
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        //    console.log('getPdf error =>', error);
        this.errorMessage = 'PDF filen kunde inte laddas från servern.';
        this.loading = false;
      }
    },

    // Check for pages rendering
    queueRenderPage(num) {
      if (this.pageIsRedering) {
        this.pageNumIsPending = num;
      } else {
        this.renderPage(num);
      }
    },
    // Render the page
    renderPage(num) {
      this.pageIsRedering = true;
      // Get page
      this.pdfDoc.getPage(num).then((page) => {
        // Set scale
        const viewport = page.getViewport(this.scale);
        this.canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        if (this.context) {
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          // this.context.beginPath();
        }
        const renderContext = {
          canvasContext: this.context,
          viewport: viewport
        };
        page.render(renderContext).promise.then(() => {
          this.pageIsRedering = false;
          if (this.pageNumIsPending !== null) {
            this.renderPage(this.pageNumIsPending);
            this.pageNumIsPending = null;
          }
        });
      });
    },

    async downloadPDF(file) {
      let fileName = file.substring(file.lastIndexOf('/') + 1);

      const payload = {
        file_name: fileName
      };
      const myUrl = await axiosAPI
        .post('/api/downloads/pdf', payload, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          let raw = Buffer.from(response.data).toString('base64');
          return 'data:' + response.headers['content-type'] + ';base64,' + raw;
        })
        .catch(() => {
          this.errorMessage = 'PDF filen kunde inte hittas på servern.';
        });

      return myUrl;
    }
  },
  watch: {
    myForceCount: function () {
      let file = this.myOrderFiles[0];
      this.currentFileName = file.substring(file.lastIndexOf('/') + 1);
      this.getPDF(file);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.modal {
  width: 99%;
  height: 95vh;
  padding: 20px;
  margin: 20px auto;
  background: white;
  border-radius: 10px;

  h1 {
    color: #03cfb4;
    border: none;
    padding: 0;
  }

  p {
    font-style: normal;
  }
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #fff;
  font-size: 1.3rem;
  justify-content: space-between;
  align-items: center;
}
.modal-force-number {
  display: none;
}
.modal-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-content-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 200px) 1fr;
  min-height: 0;
  min-width: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}

.modal-text {
  margin: 0px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}
.modal-label {
  margin: 5px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 0;
}
.btn-action {
  min-width: 100px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-column-left {
  display: flex;
  flex-direction: column;
  background-color: gray;
  align-items: center;
  border: 1px inset black;
}

.single-file {
  width: 40%;
  margin-bottom: 40px;
}
.file-image {
  width: 80px;
}
.file-text {
  width: 100px;
  word-wrap: break-word;
  color: #fff;
  font-size: 0.9em;
}
.modal-column-right {
  display: flex;
  background-color: lightgray;
  overflow: scroll;
  flex-direction: column;
  border: 1px inset black;
}
.canvas-area {
  text-align: center;
  margin-bottom: 30px;
}
.modal-canvas {
  box-shadow: 10px 5px 5px grey;
}
.action-bar-left {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: gray;
  border: 1px outset black;
  position: sticky;
  top: 0;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-bar-right {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: gray;
  border: 1px outset black;
  position: sticky;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-info {
  padding: 0;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 1em;
}
</style>
